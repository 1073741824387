<template>
  <div class="app" :key="windowWidth">
    <div class="app-container" :class="{'overlay-bg': !displayMap}">
      <div class="app-header">
        <h2 class="app-header_title d-none d-lg-block px-5">
          Encuentra tu sucursal más cercana
        </h2>
      </div>
      <button
        v-if="!displayMap"
        class="btn btn-outline-success start-button"
        ref="backDropFilter"
        type="button"
        @click.prevent="loadMap()"
      >
        <img class="start-buton_image" :src="cxfHost + `/public-assets/white-pin`">
        Haz clic aquí para localizar tu sucursal
      </button>

      <label class="panel-list_title mt-4" style="width: min-content; white-space: nowrap">
        Localiza una sucursal cerca de ti
      </label>

      <div class="app-panels_row mb-4">
        <div class="panel-list_input-container">
          <div class="panel-list_input-group">
            <button
              class="btn btn-outline-success panel-list_button reset"
              type="button"
              @click.prevent="requestGeolocationAccess()"
            >
              <img class="btn-image" :src="cxfHost + `/public-assets/target-icon`">
            </button>
            <div id="geocoder"></div>
            <button
              class="btn btn-outline-success panel-list_button search"
              type="button"
              @click.prevent="dispatchSearchEvent()"
            >
              <img class="btn-image" :src="cxfHost + `/public-assets/search-icon`">
            </button>
          </div>
        </div>

        <div class="map-container_header d-none d-lg-flex w-100">
          <div class="categories-container justify-content-between w-100">
            <ul class="list-group list-group-horizontal">
              <li
                class="list-group-item category"
                :class="{'category-selected': categoriesFilters.includes(category.slug)}"
                v-for="category in primaryCategories"
                :key="category.slug"
                @click.prevent="selectCategory(category.slug)"
              >
                <img class="category-image" :src="cxfHost + `/public-assets${category.image}`">
                <span>{{ category.title }}</span>
              </li>
              <li class="list-group-item category">
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Otros
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li
                      class="secondary-category"
                      v-for="category in secondaryCategories"
                      :key="category.slug"
                      @click.prevent="selectCategory(category.slug)"
                    >
                      <img class="category-image" :src="cxfHost + `/public-assets${category.image}`">
                      <span>{{ category.title }}</span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <button type="button" class="btn filter-button" @click.prevent="openFiltersPanel()">
              <i class="fas fa-sliders-h"></i>
              <label>Filtros ({{ filterByCategories.slugs.length }})</label>
            </button>
          </div>
        </div>
      </div>

      <div class="app-content">
        <div class="panel-list d-none d-lg-block h-100">
          <div class="panel-list_results-container h-100">
            <div class="panel-list_header">
              <label class="panel-list_results">{{ resultsText }}</label>
            </div>
            <div id="list-items-container" class="panel-list_items" v-if="screenWidth > 991">
              <div v-show="!displayMap">
                <div
                  v-for="n in 2"
                  :key="n"
                  class="w-100 mb-3"
                  style="aspect-ratio: 16/9; background-color: #f5f5f5;"
                >
                </div>
              </div>

              <ListItem
                v-for="location in locations"
                :key="location.id"
                :selectedLocation="selectedLocation"
                :location="location"
                :userLocationCoords="userLocationCoords"
                :freezedUserLocationCoords="freezedUserLocationCoords"
                @locationClicked="onLocationClicked"
              />
            </div>
          </div>
        </div>

        <div class="map-container">
          <div class="map-container_content">
            <div class="map" v-if="!displayMap"></div>
            <div id="map" class="map" v-else></div>
          </div>

          <div class="map-mobile_list d-sm-block d-md-block d-lg-none">
            <div class="mobile-container">
              <div class="mobile-results d-flex align-items-center">
                <button type="button" class="btn filter-button" @click.prevent="openFiltersPanel()">
                  <i class="fas fa-sliders-h"></i>
                  <label>Filtros ({{ filterByCategories.slugs.length }})</label>
                </button>
                <label class="panel-list_results mb-0">{{ resultsText }}</label>
              </div>
              <div id="list-items-container" class="mobile-locations" v-if="screenWidth < 992">
                <ListItem
                  v-for="location in locations"
                  :key="location.id"
                  :selectedLocation="selectedLocation"
                  :location="location"
                  :userLocationCoords="userLocationCoords"
                  :freezedUserLocationCoords="freezedUserLocationCoords"
                  :isMobileVersion="true"
                  @locationClicked="onLocationClicked"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div> 

    <FiltersPanelVue
      :showPanel="showPanel"
      :categories="categories"
      :selectedCategories="selectedCategories"
      @applyCategories="onFiltersChange"
    />

    <LoadingModal v-if="status.isLoading"></LoadingModal>
  </div>
</template>

<script>
import FiltersPanelVue from './components/FiltersPanel.vue';
import ListItem from './components/ListItem.vue';
import mapMixin from '../../mixins/mapMixin';
import statsMixin from '../../mixins/statsMixin';
import LoadingModal from './LoadingModal.vue';


export default {
  inject: ['$axios'],
  mixins: [mapMixin, statsMixin],
  components: { ListItem, FiltersPanelVue, LoadingModal },
  props: {
    mapboxApiKey: {
      type: String,
      required: true
    },
    cxfHost: {
      type: String,
      required: true
    }
  },
  provide() {
    return {
      cxfHost: this.cxfHost
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      map: null,
      userLocationMarker: null,
      userLocationCoords: null,
      freezedUserLocationCoords: [],
      locations: [],
      locationsResponse: [],
      categoriesFilters: ['banco-azteca'],
      selectedLocation: null,
      selectedCategories: ['banco-azteca'],
      filterByCategories: { slugs: ['banco-azteca'], ids: [] },
      categories: [
        {
          title: 'Banco Azteca',
          image: '/azteca-icon',
          displayAsMain: true,
          hideAt: null,
          slug: 'banco-azteca'
        },
        {
          title: 'Cajeros Multiva',
          image: '/multiva-icon',
          displayAsMain: true,
          hideAt: null,
          slug: 'cajeros-multiva'
        },
        {
          title: 'Cajeros Mifel',
          image: '/mifel-icon',
          displayAsMain: true,
          hideAt: 1087,
          slug: 'cajeros-mifel'
        },
        {
          title: 'Cajeros Afirme',
          image: '/afirme-icon',
          displayAsMain: true,
          hideAt: 1242,
          slug: 'cajeros-afirme'
        },
        {
          title: 'Chedraui',
          image: '/chedraui-icon',
          displayAsMain: true,
          hideAt: 1363,
          slug: 'chedraui'
        },
        {
          title: 'Telecomm',
          image: '/telecomm-icon',
          displayAsMain: false,
          hideAt: null,
          slug: 'telecomm'
        },
        {
          title: 'Cajero Banco Bajio',
          image: '/bajio-icon',
          displayAsMain: false,
          hideAt: null,
          slug: 'cajero-banco-bajio'
        },
        {
          title: 'Canales de terceros',
          image: '/terceros-icon',
          displayAsMain: false,
          hideAt: null,
          slug: 'canales-de-terceros'
        },
      ],
      showPanel: false,
      screenWidth: null,
      isPermissionGranted: false,
      status: {
        isLoading: false
      },
      displayMap: false
    }
  },
  created() {
    this.requestGeolocationAccess();
    window.addEventListener("resize", this.handleScreenChanges);
    this.screenWidth = window.innerWidth;
  },
  destroyed() {
    window.removeEventListener("resize", this.handleScreenChanges);
  },
  async mounted() {
    this.generateUserIdentifier(); 
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    displayMap: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(async () => {
            this.initializeMap();
            let geocoder = this.createGeocoder();
            let mobilegeocoder = this.createGeocoder();

            await this.map.on('load', () => {
              const searchInputHandler = (payload) => {
                if (payload?.result?.geometry?.coordinates) {
                  this.userLocationCoords = payload.result.geometry.coordinates;
                  this.navigateTo(this.userLocationCoords);
                  let search = payload?.result?.place_name;
                  let zipCode = payload?.result?.context?.[0]?.text ?? null;
                  let locationCoords = payload?.result?.center ?? [];
                  this.saveStatistics('search_input', {
                    search,
                    zip_code: zipCode,
                    search_coordinates: locationCoords
                  });
                }
              };
              geocoder.on('result', async (event) => searchInputHandler(event));
              mobilegeocoder.on('result', async (event) => searchInputHandler(event));
            });

            document.getElementById('geocoder').appendChild(geocoder.onAdd(this.map));
            // document.getElementById('mobilegeocoder').appendChild(mobilegeocoder.onAdd(this.map));

            navigator.geolocation.watchPosition(() => {
              this.isPermissionGranted = true;
            });

            this.getLocations();
            this.navigateTo(this.userLocationCoords);
          });
        }
      },
      immediate: true
    },
    userLocationCoords: {
      async handler(newVal) {
        if (newVal && this.displayMap) {
          this.navigateTo(newVal);
          await this.getLocations();
        }
      },
      immediate: true
    },
    locations: {
      handler(newVal) {
        if (newVal) {
          this.drawPinForLocations(newVal);
        }
      },
      immediate: true,
      deep: true
    },
    filterByCategories: {
      async handler(newVal) {
        if (newVal && this.userLocationCoords) {
          await this.getLocations();
        }
      },
      immediate: true,
      deep: true
    },
  },
  computed: {
    resultsText() {
      if (!this.locations || !this.locations.length) {
        return 'No hay resultados cerca';
      }
      if (this.locations.length === 1) {
        return `Hay 1 resultado cerca`;
      }
      return `Hay ${this.locations.length} resultados cerca`;
    },
    primaryCategories() {
      if (!this.categories) return [];
      return this.categories.filter(
        c => c.displayAsMain === true && (this.screenWidth > c.hideAt)
      );
    },
    secondaryCategories() {
      if (!this.categories) return [];
      return this.categories.filter(
        c => c.displayAsMain === false || (this.screenWidth < c.hideAt)
      );
    }
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    handleScreenChanges(e) {
      this.screenWidth = e.srcElement.innerWidth;
    },
    loadMap() {
      this.displayMap = true;
      this.saveStatistics('load_map_button_clicked', null);
    },
    initializeMap() {
      mapboxgl.accessToken = `${mapboxApiKey}`;
      this.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-99.14556, 19.41944], // CDMX
        zoom: 15,
      });
      this.map.on('load', () => {
        this.map.resize();
      });
    },
    createGeocoder() {
      return new MapboxGeocoder({
        countries: 'MX',
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        placeholder: 'Direccion, C.P o colonia',
        language: 'es-MX',
      });
    },
    navigateTo(coords) {
      if (!coords || !this.map) return;
      this.map.flyTo({
        zoom: 15,
        center: coords
      });
      if (this.userLocationMarker) {
        this.userLocationMarker.remove();
      }
      this.userLocationMarker = new mapboxgl.Marker()
        .setLngLat(coords)
        .addTo(this.map);
    },
    async getLocations() {
      const longitude = this.userLocationCoords?.[0];
      const latitude = this.userLocationCoords?.[1];
      if (longitude == null || latitude == null) return;

      this.status.isLoading = true;
      await this.$axios.get(
        `/api/v1/locations/get_locations_from_cxf?longitude=${longitude}&latitude=${latitude}`
      )
      .then(response => {
        this.locationsResponse = response.data.data;
        this.applyFiltersToResponse();
      })
      .finally(() => {
        this.status.isLoading = false;
      });
    },
    drawPinForLocations(locations) {
      const oldMarkers = document.getElementsByClassName("marker");
      while (oldMarkers.length > 0) {
        oldMarkers[0].remove();
      }
      locations.forEach((item, i) => {
        const el = document.createElement('div');
        el.index = i;
        el.id = `location-${item.id}`;
        el.className = 'marker';
        const pinImage = item?.location_details?.pin_image?.slug ?? "azteca-pin";
        el.style.backgroundImage = `url(${this.cxfHost}/public-assets/${pinImage})`;
        el.addEventListener('click', () => {
          this.saveStatistics('pin_clicked', {
            sucursal_name: item?.title,
            sucursal_coordinates: item?.content_instance?.location?.geometry?.coordinates,
            sucursal_id: item?.location_id
          });
          this.onLocationClicked(item);
        });
        new mapboxgl.Marker(el)
          .setLngLat(item?.content_instance?.location?.geometry?.coordinates)
          .addTo(this.map);
      });
    },
    requestGeolocationAccess() {
      if (!('geolocation' in navigator)) {
        this.getUserLocationFromBackend();
      }
      navigator.geolocation.getCurrentPosition(position => {
        const { longitude, latitude } = position.coords;
        this.userLocationCoords = [longitude, latitude];
        this.freezedUserLocationCoords = [longitude, latitude];
        this.isPermissionGranted = true;
        this.saveStatistics('geolocation_granted', null);
      }, () => {
        this.getUserLocationFromBackend();
        this.saveStatistics('geolocation_denied', null);
      });
    },
    getUserLocationFromBackend() {
      this.isGeolocationGranted = false;
      this.$axios.get(`/api/v1/locations/user_location`)
        .then(response => {
          if (response.data?.location?.length >= 2) {
            const [lat, lng] = response.data.location;
            this.userLocationCoords = [lng, lat];
            this.freezedUserLocationCoords = [lng, lat];
          }
        })
        .finally(() => {
          this.status.isLoading = false;
        });
    },
    dispatchSearchEvent() {
      const inputs = document.getElementsByClassName('mapboxgl-ctrl-geocoder--input');
      if (!inputs.length) return;
      const input = inputs[0];
      const event = new KeyboardEvent('keydown', {
        bubbles: true, cancelable: true, keyCode: 13
      });
      input.dispatchEvent(event);
    },
    selectCategory(slug) {
      this.categoriesFilters = [slug];
      this.applyFiltersToResponse();
    },
    onLocationClicked(location) {
      this.selectedLocation = location;
      this.map.flyTo({
        zoom: 15,
        center: location?.content_instance?.location?.geometry?.coordinates
      });
      const listContainer = document.getElementById('list-items-container');
      const locationDiv = document.getElementById(`${location.id}`);
      if (this.screenWidth < 992) {
        listContainer?.scrollTo(locationDiv?.offsetLeft - 10, 0);
      } else {
        listContainer?.scrollTo(
          0,
          (locationDiv?.offsetTop ?? 0) - (listContainer?.offsetHeight / 2)
        );
      }
      for (let el of document.querySelectorAll('.marker')) {
        el.style.filter = "grayscale(100%)";
        el.style.opacity = "0.5";
      }
      const selectedPin = document.getElementById(`location-${location.id}`);
      if (selectedPin) {
        selectedPin.style.filter = 'grayscale(0%)';
        selectedPin.style.opacity = '1';
      }
    },
    openFiltersPanel() {
      this.showPanel = true;
    },
    onFiltersChange(payload) {
      this.categoriesFilters = [...payload];
      this.applyFiltersToResponse();
      this.showPanel = false;
    },
    applyFiltersToResponse() {
      this.locations = this.locationsResponse.filter(item =>
        item?.taxonomies?.length > 0 &&
        this.categoriesFilters.includes(item?.taxonomies[0]?.slug)
      );
      this.locations = this.locations.map(item => {
        const coords = item?.content_instance?.location?.geometry?.coordinates || [];
        item.distance = this.calculateDistanceInKm(coords[1], coords[0]);
        return item;
      });
      this.locations.sort((a, b) => a.distance - b.distance);
    },
    calculateDistanceInKm(lat2, lon2) {
      const R = 6371;
      const [lon1, lat1] = this.userLocationCoords || [];
      if (lon1 == null || lat1 == null) return 999999;
      const dLat = this.deg2rad(lat2 - lat1);
      const dLon = this.deg2rad(lon2 - lon1);
      const a = Math.sin(dLat / 2) ** 2 +
                Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
                Math.sin(dLon / 2) ** 2;
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return (R * c).toFixed(2);
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
  }
}
</script>

<style lang="scss">

.app {
  display: flex;
  justify-content: center;
  padding: 1em 0em 0em 0em;
  @media only screen and (max-width: 991px) {
       display: block;
       width: 100%;
      }
}

.app-container {
  position: relative;
  min-height: 100vh;

  &.overlay-bg {
    &::after {
      content: '';
      position: absolute;
      top: 0; 
      left: 0;
      width: 100%;
      height: 100%;
      background: url('https://baz.cxf.app/public-assets/back-preloader-1900')
        no-repeat center center;
      background-size: cover;
      z-index: 1;
      @media only screen and (max-width: 991px) {
        background: url('https://baz.cxf.app/public-assets/back-preloader-921') center center no-repeat #fff;
        background-size: contain ;
      }
      @media only screen and (max-width: 600px) {
        background: url('https://baz.cxf.app/public-assets/back-preloader-360') top center no-repeat #fff;
        background-size: contain ;
      }
    }
  }
}

.app-header {
  position: relative;
  display: flex;
  justify-content: center;
}

.app-header_title {
  font-size: 30px;
  font-weight: 700;
}

.start-button {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #19a44d;
  border-radius: 15px;
  width: 275px;
  max-width: 80%;
  height: fit-content;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  font-weight: 600;
}

.start-buton_image {
  width: 32px;
  height: auto;
}

.panel-list_title {
  color: #282828;
  font-size: 16px;
  margin-bottom: 1em;
  white-space: nowrap;
}

.app-panels_row {
  display: flex;
  align-items: center;
  gap: 20px 30px;
}

.panel-list_input-container {
  flex: 1;
  min-width: clamp(260px, 90vw, 390px);
}

.btn-image {
  height: 20px!important;
  width: 20px!important;
}

.panel-list_button {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 40px;
}

.search {
  background: #43b02a;
}

.app-content {
  display: flex;
  width: 100%;
  gap: 30px;
  align-items: start;
}

.panel-list {
  flex: 3;
  min-width: 390px;
}
.panel-list_results-container {
  height: 100%;
}

.panel-list_results {
  color: #282828;
  font-size: 14px;
  margin-bottom: 1em;
}

.panel-list_header { }

/* Contenedor de la lista de sucursales (scroll) */
.panel-list_items {
  min-height: 100%;
  overflow-y: auto;
  padding: 0px 5px 0px 0px;
  height: 585px;
}

/* Mapa */
.map-container {
  display: flex;
  flex-direction: column;
  width: 100%!important;
}

.map-container_content {
  flex: 8;
}

.map {
  width: 100%!important;
  height: 100%;
  max-height: 590px;
  aspect-ratio: 1/1;
  margin-top: 0;
}

/* Categorías */
.categories-container {
  display: flex;
  justify-content: space-between;
}

.map-container_header {
  flex: 8;
}

.category {
  align-items: center;
  border: none!important;
  border-right: #EAEAEA solid 1px !important;
  border-radius: 0px!important;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  gap: 5px;
  justify-content: center;
  height: 36px;
  padding: 2px 7px;
}

.category-image {
  height: 26px;
  width: 26px;
}

.category-selected {
  border-bottom: solid 3px #17a54d!important;
}

.secondary-category {
  display: flex;
  border-radius: 0px!important;
  align-content: center;
  gap: 5px;
  font-size: 13px;
  padding: 5px 10px;
}

.dropdown-menu {
  min-width: 170px!important;
}

.dropdown-toggle {
  font-size: 13px;
}

/* Vista mobile */
.map-mobile_list {
  flex: 1;
}

.mobile-container {
  display: flex;
  flex-direction: column!important;
  justify-content: space-around!important;
  flex-grow: 1;
  padding: 15px 0px 5px 0px;
}

.mobile-results {
  align-content: center;
  display: flex;
  justify-content: space-between;
  margin: 1em 0em 1em 0em;
}
.mobile-results > label {
  color: grey!important;
}

.mobile-locations {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  max-width: 852px;
}

.filter-button {
  align-content: center;
  background: #43b02a;
  color: white;
  display: flex;
  justify-content: space-between;
  height: 36px;
  min-width: 120px;
}
.filter-button > label {
  color: white;
  font-style: normal;
}

.panel-list_input-group {
  display: flex;
  align-content: center;
}

#geocoder {
  flex-grow: 1;
}
.mapboxgl-ctrl-geocoder {
  min-width: 220px !important;
}
.mapboxgl-ctrl-geocoder--input {
  color: rgba(0,0,0,.75)!important;
  font-size: 15px!important;
}
.mapboxgl-ctrl-geocoder--button {
  border: none!important;
  background: transparent!important;
  top: -3px!important;
}
.mapboxgl-ctrl-geocoder--icon-close {
  background: transparent!important;
  width: 20px;
  height: 20px;
  margin-top: 7px;
  margin-right: 3px;
}

/* Marcadores (pines) */
.marker {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
</style>
